// src/data/callout-access.ts
var CalloutAccess = /* @__PURE__ */ ((CalloutAccess2) => {
  CalloutAccess2["Member"] = "member";
  CalloutAccess2["Guest"] = "guest";
  CalloutAccess2["Anonymous"] = "anonymous";
  CalloutAccess2["OnlyAnonymous"] = "only-anonymous";
  return CalloutAccess2;
})(CalloutAccess || {});

// src/data/callout-captcha.ts
var CalloutCaptcha = /* @__PURE__ */ ((CalloutCaptcha2) => {
  CalloutCaptcha2["None"] = "none";
  CalloutCaptcha2["Guest"] = "guest";
  CalloutCaptcha2["All"] = "all";
  return CalloutCaptcha2;
})(CalloutCaptcha || {});

// src/data/callout-channel.ts
var CalloutChannel = /* @__PURE__ */ ((CalloutChannel2) => {
  CalloutChannel2["TELEGRAM"] = "telegram";
  return CalloutChannel2;
})(CalloutChannel || {});

// src/data/callout-component-base-type.ts
var CalloutComponentBaseType = /* @__PURE__ */ ((CalloutComponentBaseType2) => {
  CalloutComponentBaseType2[CalloutComponentBaseType2["CONTENT"] = 0] = "CONTENT";
  CalloutComponentBaseType2[CalloutComponentBaseType2["INPUT"] = 1] = "INPUT";
  CalloutComponentBaseType2[CalloutComponentBaseType2["INPUT_TEXT"] = 2] = "INPUT_TEXT";
  CalloutComponentBaseType2[CalloutComponentBaseType2["INPUT_SELECTABLE"] = 3] = "INPUT_SELECTABLE";
  CalloutComponentBaseType2[CalloutComponentBaseType2["NESTABLE"] = 4] = "NESTABLE";
  return CalloutComponentBaseType2;
})(CalloutComponentBaseType || {});

// src/data/callout-component-type.ts
var CalloutComponentType = /* @__PURE__ */ ((CalloutComponentType2) => {
  CalloutComponentType2["CONTENT"] = "content";
  CalloutComponentType2["INPUT_ADDRESS"] = "address";
  CalloutComponentType2["INPUT_CHECKBOX"] = "checkbox";
  CalloutComponentType2["INPUT_CURRENCY"] = "currency";
  CalloutComponentType2["INPUT_DATE_TIME"] = "datetime";
  CalloutComponentType2["INPUT_EMAIL"] = "email";
  CalloutComponentType2["INPUT_FILE"] = "file";
  CalloutComponentType2["INPUT_NUMBER"] = "number";
  CalloutComponentType2["INPUT_PHONE_NUMBER"] = "phoneNumber";
  CalloutComponentType2["INPUT_SELECT"] = "select";
  CalloutComponentType2["INPUT_SELECTABLE_RADIO"] = "radio";
  CalloutComponentType2["INPUT_SELECTABLE_SELECTBOXES"] = "selectboxes";
  CalloutComponentType2["INPUT_SIGNATURE"] = "signature";
  CalloutComponentType2["INPUT_TEXT_AREA"] = "textarea";
  CalloutComponentType2["INPUT_TEXT_FIELD"] = "textfield";
  CalloutComponentType2["INPUT_TIME"] = "time";
  CalloutComponentType2["INPUT_URL"] = "url";
  CalloutComponentType2["NESTABLE_PANEL"] = "panel";
  CalloutComponentType2["NESTABLE_TABS"] = "tabs";
  CalloutComponentType2["NESTABLE_WELL"] = "well";
  return CalloutComponentType2;
})(CalloutComponentType || {});

// src/data/callout-component-input-selectable-types.ts
var calloutComponentInputSelectableTypes = [
  "radio" /* INPUT_SELECTABLE_RADIO */,
  "selectboxes" /* INPUT_SELECTABLE_SELECTBOXES */
];

// src/data/callout-component-input-text-types.ts
var calloutComponentInputTextTypes = [
  "textarea" /* INPUT_TEXT_AREA */,
  "textfield" /* INPUT_TEXT_FIELD */
];

// src/data/callout-component-input-types.ts
var calloutComponentInputTypes = [
  ...calloutComponentInputSelectableTypes,
  ...calloutComponentInputTextTypes,
  "address" /* INPUT_ADDRESS */,
  "checkbox" /* INPUT_CHECKBOX */,
  "currency" /* INPUT_CURRENCY */,
  "datetime" /* INPUT_DATE_TIME */,
  "email" /* INPUT_EMAIL */,
  "file" /* INPUT_FILE */,
  "number" /* INPUT_NUMBER */,
  "phoneNumber" /* INPUT_PHONE_NUMBER */,
  "select" /* INPUT_SELECT */,
  "signature" /* INPUT_SIGNATURE */,
  "time" /* INPUT_TIME */,
  "url" /* INPUT_URL */
];

// src/data/callout-component-nestable-types.ts
var calloutComponentNestableTypes = [
  "panel" /* NESTABLE_PANEL */,
  "well" /* NESTABLE_WELL */,
  "tabs" /* NESTABLE_TABS */
];

// src/data/callout-component-types.ts
var calloutComponentTypes = [
  ...calloutComponentNestableTypes,
  ...calloutComponentInputTypes,
  "content" /* CONTENT */
];

// src/data/contact-mfa-type.ts
var CONTACT_MFA_TYPE = /* @__PURE__ */ ((CONTACT_MFA_TYPE2) => {
  CONTACT_MFA_TYPE2["TOTP"] = "totp";
  return CONTACT_MFA_TYPE2;
})(CONTACT_MFA_TYPE || {});

// src/data/content-ids.ts
var contentIds = [
  "contacts",
  "email",
  "general",
  "join",
  "join/setup",
  "profile",
  "share",
  "payment",
  "telegram"
];

// src/data/contribution-period.ts
var ContributionPeriod = /* @__PURE__ */ ((ContributionPeriod2) => {
  ContributionPeriod2["Monthly"] = "monthly";
  ContributionPeriod2["Annually"] = "annually";
  return ContributionPeriod2;
})(ContributionPeriod || {});

// src/data/contribution-type.ts
var ContributionType = /* @__PURE__ */ ((ContributionType2) => {
  ContributionType2["Automatic"] = "Automatic";
  ContributionType2["Manual"] = "Manual";
  ContributionType2["Gift"] = "Gift";
  ContributionType2["None"] = "None";
  return ContributionType2;
})(ContributionType || {});

// src/data/get-contact-with.ts
var GetContactWith = /* @__PURE__ */ ((GetContactWith2) => {
  GetContactWith2["Contribution"] = "contribution";
  GetContactWith2["Profile"] = "profile";
  GetContactWith2["Roles"] = "roles";
  GetContactWith2["Tags"] = "tags";
  GetContactWith2["IsReviewer"] = "isReviewer";
  return GetContactWith2;
})(GetContactWith || {});

// src/data/item-status.ts
var ItemStatus = /* @__PURE__ */ ((ItemStatus2) => {
  ItemStatus2["Draft"] = "draft";
  ItemStatus2["Scheduled"] = "scheduled";
  ItemStatus2["Open"] = "open";
  ItemStatus2["Ended"] = "ended";
  return ItemStatus2;
})(ItemStatus || {});

// src/data/login-codes.ts
var LOGIN_CODES = /* @__PURE__ */ ((LOGIN_CODES2) => {
  LOGIN_CODES2["LOCKED"] = "account-locked";
  LOGIN_CODES2["LOGGED_IN"] = "logged-in";
  LOGIN_CODES2["LOGIN_FAILED"] = "login-failed";
  LOGIN_CODES2["REQUIRES_2FA"] = "requires-2fa";
  LOGIN_CODES2["UNSUPPORTED_2FA"] = "unsupported-2fa";
  LOGIN_CODES2["INVALID_TOKEN"] = "invalid-token";
  LOGIN_CODES2["MISSING_TOKEN"] = "missing-token";
  return LOGIN_CODES2;
})(LOGIN_CODES || {});

// src/data/membership-status.ts
var MembershipStatus = /* @__PURE__ */ ((MembershipStatus2) => {
  MembershipStatus2["Active"] = "active";
  MembershipStatus2["Expiring"] = "expiring";
  MembershipStatus2["Expired"] = "expired";
  MembershipStatus2["None"] = "none";
  return MembershipStatus2;
})(MembershipStatus || {});

// src/data/newsletter-status.ts
var NewsletterStatus = /* @__PURE__ */ ((NewsletterStatus2) => {
  NewsletterStatus2["Subscribed"] = "subscribed";
  NewsletterStatus2["Unsubscribed"] = "unsubscribed";
  NewsletterStatus2["Cleaned"] = "cleaned";
  NewsletterStatus2["Pending"] = "pending";
  NewsletterStatus2["None"] = "none";
  return NewsletterStatus2;
})(NewsletterStatus || {});

// src/data/payment-method.ts
var PaymentMethod = /* @__PURE__ */ ((PaymentMethod2) => {
  PaymentMethod2["StripeCard"] = "s_card";
  PaymentMethod2["StripeSEPA"] = "s_sepa";
  PaymentMethod2["StripeBACS"] = "s_bacs";
  PaymentMethod2["StripePayPal"] = "s_paypal";
  PaymentMethod2["GoCardlessDirectDebit"] = "gc_direct-debit";
  return PaymentMethod2;
})(PaymentMethod || {});

// src/data/payment-status.ts
var PaymentStatus = /* @__PURE__ */ ((PaymentStatus2) => {
  PaymentStatus2["Draft"] = "draft";
  PaymentStatus2["Pending"] = "pending";
  PaymentStatus2["Successful"] = "successful";
  PaymentStatus2["Failed"] = "failed";
  PaymentStatus2["Cancelled"] = "cancelled";
  return PaymentStatus2;
})(PaymentStatus || {});

// src/data/reset-security-flow-error-code.ts
var RESET_SECURITY_FLOW_ERROR_CODE = /* @__PURE__ */ ((RESET_SECURITY_FLOW_ERROR_CODE2) => {
  RESET_SECURITY_FLOW_ERROR_CODE2["NOT_FOUND"] = "not-found";
  RESET_SECURITY_FLOW_ERROR_CODE2["WRONG_TYPE"] = "wrong-type";
  RESET_SECURITY_FLOW_ERROR_CODE2["WRONG_MFA_TYPE"] = "wrong-mfa-type";
  RESET_SECURITY_FLOW_ERROR_CODE2["MFA_TOKEN_REQUIRED"] = "mfa-token-required";
  return RESET_SECURITY_FLOW_ERROR_CODE2;
})(RESET_SECURITY_FLOW_ERROR_CODE || {});

// src/data/reset-security-flow-type.ts
var RESET_SECURITY_FLOW_TYPE = /* @__PURE__ */ ((RESET_SECURITY_FLOW_TYPE2) => {
  RESET_SECURITY_FLOW_TYPE2["PASSWORD"] = "password";
  RESET_SECURITY_FLOW_TYPE2["TOTP"] = "totp";
  return RESET_SECURITY_FLOW_TYPE2;
})(RESET_SECURITY_FLOW_TYPE || {});

// src/error/InvalidRule.ts
var InvalidRule = class _InvalidRule extends Error {
  constructor(rule, message) {
    super();
    this.rule = rule;
    this.message = message;
    Object.setPrototypeOf(this, _InvalidRule.prototype);
  }
};

// src/search/api-keys.ts
var apiKeyFilters = {
  id: {
    type: "text"
  },
  createdAt: {
    type: "date"
  }
};

// src/search/callouts.ts
var calloutFilters = {
  id: {
    type: "text"
  },
  slug: {
    type: "text"
  },
  title: {
    type: "text"
  },
  status: {
    type: "enum",
    options: [
      "draft" /* Draft */,
      "scheduled" /* Scheduled */,
      "open" /* Open */,
      "ended" /* Ended */
    ]
  },
  answeredBy: {
    type: "contact"
  },
  starts: {
    type: "date",
    nullable: true
  },
  expires: {
    type: "date",
    nullable: true
  },
  hidden: {
    type: "boolean"
  },
  channels: {
    type: "array"
  },
  canReview: {
    type: "boolean"
  }
};
var calloutResponseFilters = {
  id: {
    type: "text"
  },
  contact: {
    type: "contact",
    nullable: true
  },
  calloutId: {
    type: "text"
  },
  createdAt: {
    type: "date"
  },
  updatedAt: {
    type: "date"
  },
  bucket: {
    type: "text",
    nullable: true
  },
  tags: {
    type: "array"
  },
  assignee: {
    type: "contact",
    nullable: true
  },
  answers: {
    type: "blob"
  }
};
var calloutResponseCommentFilters = {
  id: {
    type: "text"
  },
  calloutId: {
    type: "text"
  },
  responseId: {
    type: "text"
  },
  contact: {
    type: "contact"
  },
  createdAt: {
    type: "date"
  },
  updatedAt: {
    type: "date"
  },
  text: {
    type: "text"
  }
};
var calloutTagFilters = {
  // ...tagFilters,
  id: {
    type: "text"
  },
  name: {
    type: "text"
  },
  description: {
    type: "text"
  },
  calloutId: {
    type: "text"
  }
};
var calloutChannelFilters = {
  id: {
    type: "text"
  },
  name: {
    type: "text"
  },
  description: {
    type: "text"
  },
  calloutId: {
    type: "text"
  }
};
var calloutReviewerFilters = {
  id: {
    type: "text"
  },
  contact: {
    type: "contact"
  },
  calloutId: {
    type: "text"
  }
};

// src/search/contacts.ts
var RoleTypes = ["member", "admin", "superadmin"];
var contactFilters = {
  id: {
    type: "contact"
  },
  firstname: {
    type: "text"
  },
  lastname: {
    type: "text"
  },
  email: {
    type: "text"
  },
  joined: {
    type: "date"
  },
  lastSeen: {
    type: "date"
  },
  contributionCancelled: {
    type: "date",
    nullable: true
  },
  contributionType: {
    type: "enum",
    options: [
      "Automatic" /* Automatic */,
      "Gift" /* Gift */,
      "Manual" /* Manual */,
      "None" /* None */
    ]
  },
  contributionMonthlyAmount: {
    type: "number"
  },
  contributionPeriod: {
    type: "enum",
    options: [
      "monthly" /* Monthly */,
      "annually" /* Annually */
    ]
  },
  deliveryOptIn: {
    type: "boolean"
  },
  newsletterStatus: {
    type: "enum",
    options: [
      "subscribed" /* Subscribed */,
      "unsubscribed" /* Unsubscribed */,
      "cleaned" /* Cleaned */,
      "pending" /* Pending */,
      "none" /* None */
    ]
  },
  newsletterGroups: {
    type: "array"
  },
  activePermission: {
    type: "enum",
    options: RoleTypes
  },
  activeMembership: {
    type: "boolean"
  },
  membershipStarts: {
    type: "date"
  },
  membershipExpires: {
    type: "date"
  },
  manualPaymentSource: {
    type: "text",
    nullable: true
  },
  tags: {
    type: "array"
  }
};
var contactTagFilters = {
  // ...tagFilters,
  id: {
    type: "text"
  },
  name: {
    type: "text"
  },
  description: {
    type: "text"
  },
  contactId: {
    type: "text"
  }
};
var contactCalloutFilters = {
  hasAnswered: {
    type: "boolean"
  }
};

// src/search/notices.ts
var noticeFilters = {
  id: {
    type: "text"
  },
  createdAt: {
    type: "date"
  },
  updatedAt: {
    type: "date"
  },
  name: {
    type: "text"
  },
  expires: {
    type: "date",
    nullable: true
  },
  enabled: {
    type: "boolean"
  },
  text: {
    type: "text"
  },
  status: {
    type: "enum",
    options: [
      "draft" /* Draft */,
      "scheduled" /* Scheduled */,
      "open" /* Open */,
      "ended" /* Ended */
    ]
  }
};

// src/search/operators.ts
var equalityOperators = {
  equal: { args: 1 },
  not_equal: { args: 1 }
};
var orderedOperators = {
  between: { args: 2 },
  not_between: { args: 2 },
  less: { args: 1 },
  greater: { args: 1 },
  less_or_equal: { args: 1 },
  greater_or_equal: { args: 1 }
};
var stringOperators = {
  begins_with: { args: 1 },
  ends_with: { args: 1 },
  not_begins_with: { args: 1 },
  not_ends_with: { args: 1 }
};
var numericOperators = {
  ...equalityOperators,
  ...orderedOperators
};
var arrayOperators = {
  contains: { args: 1 },
  not_contains: { args: 1 }
};
var nullableOperators = {
  is_empty: { args: 0 },
  is_not_empty: { args: 0 }
};
var operatorsByType = {
  text: { ...equalityOperators, ...arrayOperators, ...stringOperators },
  blob: arrayOperators,
  date: numericOperators,
  number: numericOperators,
  boolean: { equal: equalityOperators.equal },
  array: { ...arrayOperators, ...nullableOperators },
  enum: equalityOperators,
  contact: equalityOperators
};
var operatorsByTypeMap = operatorsByType;
var ruleOperators = [
  "equal",
  "not_equal",
  "less",
  "less_or_equal",
  "greater",
  "greater_or_equal",
  "between",
  "not_between",
  "begins_with",
  "not_begins_with",
  "contains",
  "not_contains",
  "ends_with",
  "not_ends_with",
  "is_empty",
  "is_not_empty"
];

// src/search/payments.ts
var paymentFilters = {
  id: {
    type: "text"
  },
  contact: {
    type: "contact"
  },
  chargeDate: {
    type: "date"
  },
  amount: {
    type: "number"
  },
  status: {
    type: "enum",
    options: [
      "draft" /* Draft */,
      "pending" /* Pending */,
      "successful" /* Successful */,
      "failed" /* Failed */,
      "cancelled" /* Cancelled */
    ]
  }
};

// src/search/segments.ts
var segmentFilters = {
  id: {
    type: "text"
  },
  name: {
    type: "text"
  },
  description: {
    type: "text"
  }
};

// src/search/tags.ts
var tagFilters = {
  id: {
    type: "text"
  },
  name: {
    type: "text"
  },
  description: {
    type: "text"
  }
};

// src/utils/callouts.ts
function convertValuesToOptions(values) {
  return values.map(({ value }) => value);
}
function convertComponentToFilter(component) {
  const baseItem = {
    label: component.label || component.fullKey,
    nullable: true
  };
  if (isCalloutComponentOfType(component, "checkbox" /* INPUT_CHECKBOX */)) {
    return { ...baseItem, type: "boolean", nullable: false };
  }
  if (isCalloutComponentOfType(component, "number" /* INPUT_NUMBER */)) {
    return { ...baseItem, type: "number" };
  }
  if (isCalloutComponentOfType(
    component,
    "select" /* INPUT_SELECT */
  )) {
    return {
      ...baseItem,
      type: "enum",
      options: convertValuesToOptions(component.data.values)
    };
  }
  if (isCalloutComponentOfBaseType(
    component,
    3 /* INPUT_SELECTABLE */
  )) {
    return {
      ...baseItem,
      type: component.type === "radio" /* INPUT_SELECTABLE_RADIO */ ? "enum" : "array",
      options: convertValuesToOptions(component.values)
    };
  }
  if (isCalloutComponentOfType(component, "textarea" /* INPUT_TEXT_AREA */)) {
    return { ...baseItem, type: "blob" };
  }
  return { ...baseItem, type: "text" };
}
function getSelectableLabelFromValue(component, value) {
  if (isCalloutComponentOfBaseType(
    component,
    3 /* INPUT_SELECTABLE */
  )) {
    return component.values.find((v) => v.value === value)?.label || value;
  }
  if (isCalloutComponentOfType(component, "select" /* INPUT_SELECT */)) {
    return component.data.values.find((v) => v.value === value)?.label || value;
  }
  return value;
}
function isCalloutComponentOfType(component, type) {
  return "type" in component && component.type === type;
}
function isCalloutComponentOfBaseType(component, type) {
  if (!("type" in component)) {
    return false;
  }
  if (type === 0 /* CONTENT */) {
    return isCalloutComponentOfType(
      component,
      "content" /* CONTENT */
    );
  }
  if (type === 1 /* INPUT */) {
    return calloutComponentInputTypes.includes(
      component.type
    );
  }
  if (type === 2 /* INPUT_TEXT */) {
    return calloutComponentInputTextTypes.includes(
      component.type
    );
  }
  if (type === 3 /* INPUT_SELECTABLE */) {
    return calloutComponentInputSelectableTypes.includes(
      component.type
    );
  }
  if (type === 4 /* NESTABLE */) {
    return calloutComponentNestableTypes.includes(
      component.type
    );
  }
  return false;
}
function flattenComponents(components) {
  return components.flatMap(
    (component) => isCalloutComponentOfBaseType(component, 4 /* NESTABLE */) ? [component, ...flattenComponents(component.components)] : [component]
  );
}
function filterComponents(components, filterFn) {
  return components.filter(filterFn).map((component) => {
    return {
      ...component,
      ...isCalloutComponentOfBaseType(
        component,
        4 /* NESTABLE */
      ) && {
        components: filterComponents(component.components, filterFn)
      }
    };
  });
}
function getCalloutComponents(formSchema) {
  return formSchema.slides.flatMap(
    (slide) => flattenComponents(slide.components).map((component) => ({
      ...component,
      slideId: slide.id,
      fullKey: `${slide.id}.${component.key}`
    }))
  );
}
function getCalloutFilters(formSchema) {
  const items = getCalloutComponents(formSchema).filter((c) => c.input).map((c) => [`answers.${c.fullKey}`, convertComponentToFilter(c)]);
  return Object.fromEntries(items);
}
function isAddressAnswer(answer) {
  return !!answer && typeof answer === "object" && "geometry" in answer;
}
function isFileUploadAnswer(answer) {
  return !!answer && typeof answer === "object" && "url" in answer;
}
function stringifyAnswer(component, answer) {
  if (Array.isArray(answer)) {
    return answer.map((a) => stringifyAnswer(component, a)).join(", ");
  } else if (!answer) {
    return "";
  } else if (isAddressAnswer(answer)) {
    return answer.geometry.location.lat + ", " + answer.geometry.location.lng;
  } else if (isFileUploadAnswer(answer)) {
    return answer.url;
  } else if (typeof answer === "object") {
    return Object.entries(answer).filter(([, selected]) => selected).map(([value]) => getSelectableLabelFromValue(component, value)).join(", ");
  } else if (typeof answer === "string") {
    return getSelectableLabelFromValue(component, answer);
  } else {
    return answer.toString();
  }
}

// src/utils/slug.ts
function getWholeCharAndI(str, i) {
  const code = str.charCodeAt(i);
  if (isNaN(code)) {
    throw new RangeError(
      "Index " + i + ' out of range for string "' + str + '"; please open an issue at https://github.com/Trott/slug/issues/new'
    );
  }
  if (code < 55296 || code > 57343) {
    return [str.charAt(i), i];
  }
  if (code >= 55296 && code <= 56319) {
    if (str.length <= i + 1) {
      return [" ", i];
    }
    const next = str.charCodeAt(i + 1);
    if (next < 56320 || next > 57343) {
      return [" ", i];
    }
    return [str.charAt(i) + str.charAt(i + 1), i + 1];
  }
  if (i === 0) {
    return [" ", i];
  }
  const prev = str.charCodeAt(i - 1);
  if (prev < 55296 || prev > 56319) {
    return [" ", i];
  }
  throw new Error(
    'String "' + str + '" reaches code believed to be unreachable; please open an issue at https://github.com/Trott/slug/issues/new'
  );
}
function base64(input) {
  return btoa(unescape(encodeURIComponent(input)));
}
function slug(s, opts) {
  let result = slugify(s, opts);
  const fallback = opts && opts.fallback !== void 0 ? opts.fallback : defaults.fallback;
  if (fallback === true && result === "") {
    let input = "";
    for (let i = 0; i < s.length; i++) {
      const charAndI = getWholeCharAndI(s, i);
      i = charAndI[1];
      input += charAndI[0];
    }
    result = slugify(base64(input), opts);
  }
  return result;
}
var locales = {
  // http://www.eki.ee/wgrs/rom1_bg.pdf
  bg: {
    \u0419: "Y",
    \u0439: "y",
    X: "H",
    x: "h",
    \u0426: "Ts",
    \u0446: "ts",
    \u0429: "Sht",
    \u0449: "sht",
    \u042A: "A",
    \u044A: "a",
    \u042C: "Y",
    \u044C: "y"
  },
  // Need a reference URL for German, although this is pretty well-known.
  de: { \u00C4: "AE", \u00E4: "ae", \u00D6: "OE", \u00F6: "oe", \u00DC: "UE", \u00FC: "ue" },
  // Need a reference URL for Serbian.
  sr: { \u0111: "dj", \u0110: "DJ" },
  // https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/864314/ROMANIZATION_OF_UKRAINIAN.pdf
  uk: {
    \u0418: "Y",
    \u0438: "y",
    \u0419: "Y",
    \u0439: "y",
    \u0426: "Ts",
    \u0446: "ts",
    \u0425: "Kh",
    \u0445: "kh",
    \u0429: "Shch",
    \u0449: "shch",
    \u0413: "H",
    \u0433: "h"
  }
};
function slugify(str, partialOpts) {
  const mode = partialOpts?.mode || defaults.mode;
  const opts = { ...defaults.modes[mode], ...partialOpts };
  const localeMap = !!opts.locale && locales[opts.locale] || {};
  let lengths = [];
  for (const key in opts.multicharmap) {
    if (!Object.prototype.hasOwnProperty.call(opts.multicharmap, key)) {
      continue;
    }
    const len = key.length;
    if (lengths.indexOf(len) === -1) {
      lengths.push(len);
    }
  }
  lengths = lengths.sort(function(a, b) {
    return b - a;
  });
  const disallowedChars = opts.mode === "rfc3986" ? /[^\w\s\-.~]/ : /[^A-Za-z0-9\s]/;
  let result = "";
  for (let char, i = 0, l = str.length; i < l; i++) {
    char = str[i];
    let matchedMultichar = false;
    for (let j = 0; j < lengths.length; j++) {
      const len = lengths[j];
      const substr = str.substr(i, len);
      if (opts.multicharmap[substr]) {
        i += len - 1;
        char = opts.multicharmap[substr];
        matchedMultichar = true;
        break;
      }
    }
    if (!matchedMultichar) {
      if (localeMap[char]) {
        char = localeMap[char];
      } else if (opts.charmap[char]) {
        char = opts.charmap[char].replace(opts.replacement, " ");
      } else if (char.includes(opts.replacement)) {
        char = char.replace(opts.replacement, " ");
      } else {
        char = char.replace(disallowedChars, "");
      }
    }
    result += char;
  }
  if (opts.remove) {
    result = result.replace(opts.remove, "");
  }
  if (opts.trim) {
    result = result.trim();
  }
  result = result.replace(/\s+/g, opts.replacement);
  if (opts.lower) {
    result = result.toLowerCase();
  }
  return result;
}
var initialMulticharmap = {
  // multibyte devanagari characters (hindi, sanskrit, etc.)
  \u092B\u093C: "Fi",
  \u0917\u093C: "Ghi",
  \u0916\u093C: "Khi",
  \u0915\u093C: "Qi",
  \u0921\u093C: "ugDha",
  \u0922\u093C: "ugDhha",
  \u092F\u093C: "Yi",
  \u091C\u093C: "Za",
  // hebrew
  // Refs: http://www.eki.ee/wgrs/rom1_he.pdf
  // Refs: https://en.wikipedia.org/wiki/Niqqud
  \u05D1\u05B4\u05D9: "i",
  \u05D1\u05B5: "e",
  \u05D1\u05B5\u05D9: "e",
  \u05D1\u05B6: "e",
  \u05D1\u05B7: "a",
  \u05D1\u05B8: "a",
  \u05D1\u05B9: "o",
  \u05D5\u05B9: "o",
  \u05D1\u05BB: "u",
  \u05D5\u05BC: "u",
  \u05D1\u05BC: "b",
  \u05DB\u05BC: "k",
  \u05DA\u05BC: "k",
  \u05E4\u05BC: "p",
  \u05E9\u05C1: "sh",
  \u05E9\u05C2: "s",
  \u05D1\u05B0: "e",
  \u05D7\u05B1: "e",
  \u05D7\u05B2: "a",
  \u05D7\u05B3: "o",
  \u05D1\u05B4: "i"
};
var initialCharmap = {
  // latin
  \u00C0: "A",
  \u00C1: "A",
  \u00C2: "A",
  \u00C3: "A",
  \u00C4: "A",
  \u00C5: "A",
  \u00C6: "AE",
  \u00C7: "C",
  \u00C8: "E",
  \u00C9: "E",
  \u00CA: "E",
  \u00CB: "E",
  \u00CC: "I",
  \u00CD: "I",
  \u00CE: "I",
  \u00CF: "I",
  \u00D0: "D",
  \u00D1: "N",
  \u00D2: "O",
  \u00D3: "O",
  \u00D4: "O",
  \u00D5: "O",
  \u00D6: "O",
  \u0150: "O",
  \u00D8: "O",
  \u014C: "O",
  \u00D9: "U",
  \u00DA: "U",
  \u00DB: "U",
  \u00DC: "U",
  \u0170: "U",
  \u00DD: "Y",
  \u00DE: "TH",
  \u00DF: "ss",
  \u00E0: "a",
  \u00E1: "a",
  \u00E2: "a",
  \u00E3: "a",
  \u00E4: "a",
  \u00E5: "a",
  \u00E6: "ae",
  \u00E7: "c",
  \u00E8: "e",
  \u00E9: "e",
  \u00EA: "e",
  \u00EB: "e",
  \u00EC: "i",
  \u00ED: "i",
  \u00EE: "i",
  \u00EF: "i",
  \u00F0: "d",
  \u00F1: "n",
  \u00F2: "o",
  \u00F3: "o",
  \u00F4: "o",
  \u00F5: "o",
  \u00F6: "o",
  \u0151: "o",
  \u00F8: "o",
  \u014D: "o",
  \u0152: "OE",
  \u0153: "oe",
  \u00F9: "u",
  \u00FA: "u",
  \u00FB: "u",
  \u00FC: "u",
  \u0171: "u",
  \u00FD: "y",
  \u00FE: "th",
  \u00FF: "y",
  "\u1E9E": "SS",
  // greek
  \u03B1: "a",
  \u03B2: "b",
  \u03B3: "g",
  \u03B4: "d",
  \u03B5: "e",
  \u03B6: "z",
  \u03B7: "h",
  \u03B8: "th",
  \u03B9: "i",
  \u03BA: "k",
  \u03BB: "l",
  \u03BC: "m",
  \u03BD: "n",
  \u03BE: "3",
  \u03BF: "o",
  \u03C0: "p",
  \u03C1: "r",
  \u03C3: "s",
  \u03C4: "t",
  \u03C5: "y",
  \u03C6: "f",
  \u03C7: "x",
  \u03C8: "ps",
  \u03C9: "w",
  \u03AC: "a",
  \u03AD: "e",
  \u03AF: "i",
  \u03CC: "o",
  \u03CD: "y",
  \u03AE: "h",
  \u03CE: "w",
  \u03C2: "s",
  \u03CA: "i",
  \u03B0: "y",
  \u03CB: "y",
  \u0390: "i",
  \u0391: "A",
  \u0392: "B",
  \u0393: "G",
  \u0394: "D",
  \u0395: "E",
  \u0396: "Z",
  \u0397: "H",
  \u0398: "Th",
  \u0399: "I",
  \u039A: "K",
  \u039B: "L",
  \u039C: "M",
  \u039D: "N",
  \u039E: "3",
  \u039F: "O",
  \u03A0: "P",
  \u03A1: "R",
  \u03A3: "S",
  \u03A4: "T",
  \u03A5: "Y",
  \u03A6: "F",
  \u03A7: "X",
  \u03A8: "PS",
  \u03A9: "W",
  \u0386: "A",
  \u0388: "E",
  \u038A: "I",
  \u038C: "O",
  \u038E: "Y",
  \u0389: "H",
  \u038F: "W",
  \u03AA: "I",
  \u03AB: "Y",
  // turkish
  \u015F: "s",
  \u015E: "S",
  \u0131: "i",
  \u0130: "I",
  \u011F: "g",
  \u011E: "G",
  // russian
  \u0430: "a",
  \u0431: "b",
  \u0432: "v",
  \u0433: "g",
  \u0434: "d",
  \u0435: "e",
  \u0451: "yo",
  \u0436: "zh",
  \u0437: "z",
  \u0438: "i",
  \u0439: "j",
  \u043A: "k",
  \u043B: "l",
  \u043C: "m",
  \u043D: "n",
  \u043E: "o",
  \u043F: "p",
  \u0440: "r",
  \u0441: "s",
  \u0442: "t",
  \u0443: "u",
  \u0444: "f",
  \u0445: "h",
  \u0446: "c",
  \u0447: "ch",
  \u0448: "sh",
  \u0449: "sh",
  \u044A: "u",
  \u044B: "y",
  \u044C: "",
  \u044D: "e",
  \u044E: "yu",
  \u044F: "ya",
  \u0410: "A",
  \u0411: "B",
  \u0412: "V",
  \u0413: "G",
  \u0414: "D",
  \u0415: "E",
  \u0401: "Yo",
  \u0416: "Zh",
  \u0417: "Z",
  \u0418: "I",
  \u0419: "J",
  \u041A: "K",
  \u041B: "L",
  \u041C: "M",
  \u041D: "N",
  \u041E: "O",
  \u041F: "P",
  \u0420: "R",
  \u0421: "S",
  \u0422: "T",
  \u0423: "U",
  \u0424: "F",
  \u0425: "H",
  \u0426: "C",
  \u0427: "Ch",
  \u0428: "Sh",
  \u0429: "Sh",
  \u042A: "U",
  \u042B: "Y",
  \u042C: "",
  \u042D: "E",
  \u042E: "Yu",
  \u042F: "Ya",
  // ukranian
  \u0404: "Ye",
  \u0406: "I",
  \u0407: "Yi",
  \u0490: "G",
  \u0454: "ye",
  \u0456: "i",
  \u0457: "yi",
  \u0491: "g",
  // czech
  \u010D: "c",
  \u010F: "d",
  \u011B: "e",
  \u0148: "n",
  \u0159: "r",
  \u0161: "s",
  \u0165: "t",
  \u016F: "u",
  \u017E: "z",
  \u010C: "C",
  \u010E: "D",
  \u011A: "E",
  \u0147: "N",
  \u0158: "R",
  \u0160: "S",
  \u0164: "T",
  \u016E: "U",
  \u017D: "Z",
  // slovak
  \u013E: "l",
  \u013A: "l",
  \u0155: "r",
  \u013D: "L",
  \u0139: "L",
  \u0154: "R",
  // polish
  \u0105: "a",
  \u0107: "c",
  \u0119: "e",
  \u0142: "l",
  \u0144: "n",
  \u015B: "s",
  \u017A: "z",
  \u017C: "z",
  \u0104: "A",
  \u0106: "C",
  \u0118: "E",
  \u0141: "L",
  \u0143: "N",
  \u015A: "S",
  \u0179: "Z",
  \u017B: "Z",
  // latvian
  \u0101: "a",
  \u0113: "e",
  \u0123: "g",
  \u012B: "i",
  \u0137: "k",
  \u013C: "l",
  \u0146: "n",
  \u016B: "u",
  \u0100: "A",
  \u0112: "E",
  \u0122: "G",
  \u012A: "I",
  \u0136: "K",
  \u013B: "L",
  \u0145: "N",
  \u016A: "U",
  // arabic
  \u0623: "a",
  \u0625: "i",
  \u0628: "b",
  \u062A: "t",
  \u062B: "th",
  \u062C: "g",
  \u062D: "h",
  \u062E: "kh",
  \u062F: "d",
  \u0630: "th",
  \u0631: "r",
  \u0632: "z",
  \u0633: "s",
  \u0634: "sh",
  \u0635: "s",
  \u0636: "d",
  \u0637: "t",
  \u0638: "th",
  \u0639: "aa",
  \u063A: "gh",
  \u0641: "f",
  \u0642: "k",
  \u0643: "k",
  \u0644: "l",
  \u0645: "m",
  \u0646: "n",
  \u0647: "h",
  \u0648: "o",
  \u064A: "y",
  \u0621: "aa",
  \u0629: "a",
  // farsi
  \u0622: "a",
  \u0627: "a",
  \u067E: "p",
  \u0698: "zh",
  \u06AF: "g",
  \u0686: "ch",
  \u06A9: "k",
  \u06CC: "i",
  // lithuanian
  \u0117: "e",
  \u012F: "i",
  \u0173: "u",
  \u0116: "E",
  \u012E: "I",
  \u0172: "U",
  // romanian
  \u021B: "t",
  \u021A: "T",
  \u0163: "t",
  \u0162: "T",
  \u0219: "s",
  \u0218: "S",
  \u0103: "a",
  \u0102: "A",
  // vietnamese
  \u1EA0: "A",
  \u1EA2: "A",
  \u1EA6: "A",
  \u1EA4: "A",
  \u1EAC: "A",
  \u1EA8: "A",
  \u1EAA: "A",
  \u1EB0: "A",
  \u1EAE: "A",
  \u1EB6: "A",
  \u1EB2: "A",
  \u1EB4: "A",
  \u1EB8: "E",
  \u1EBA: "E",
  \u1EBC: "E",
  \u1EC0: "E",
  \u1EBE: "E",
  \u1EC6: "E",
  \u1EC2: "E",
  \u1EC4: "E",
  \u1ECA: "I",
  \u1EC8: "I",
  \u0128: "I",
  \u1ECC: "O",
  \u1ECE: "O",
  \u1ED2: "O",
  \u1ED0: "O",
  \u1ED8: "O",
  \u1ED4: "O",
  \u1ED6: "O",
  \u01A0: "O",
  \u1EDC: "O",
  \u1EDA: "O",
  \u1EE2: "O",
  \u1EDE: "O",
  \u1EE0: "O",
  \u1EE4: "U",
  \u1EE6: "U",
  \u0168: "U",
  \u01AF: "U",
  \u1EEA: "U",
  \u1EE8: "U",
  \u1EF0: "U",
  \u1EEC: "U",
  \u1EEE: "U",
  \u1EF2: "Y",
  \u1EF4: "Y",
  \u1EF6: "Y",
  \u1EF8: "Y",
  \u0110: "D",
  \u1EA1: "a",
  \u1EA3: "a",
  \u1EA7: "a",
  \u1EA5: "a",
  \u1EAD: "a",
  \u1EA9: "a",
  \u1EAB: "a",
  \u1EB1: "a",
  \u1EAF: "a",
  \u1EB7: "a",
  \u1EB3: "a",
  \u1EB5: "a",
  \u1EB9: "e",
  \u1EBB: "e",
  \u1EBD: "e",
  \u1EC1: "e",
  \u1EBF: "e",
  \u1EC7: "e",
  \u1EC3: "e",
  \u1EC5: "e",
  \u1ECB: "i",
  \u1EC9: "i",
  \u0129: "i",
  \u1ECD: "o",
  \u1ECF: "o",
  \u1ED3: "o",
  \u1ED1: "o",
  \u1ED9: "o",
  \u1ED5: "o",
  \u1ED7: "o",
  \u01A1: "o",
  \u1EDD: "o",
  \u1EDB: "o",
  \u1EE3: "o",
  \u1EDF: "o",
  \u1EE1: "o",
  \u1EE5: "u",
  \u1EE7: "u",
  \u0169: "u",
  \u01B0: "u",
  \u1EEB: "u",
  \u1EE9: "u",
  \u1EF1: "u",
  \u1EED: "u",
  \u1EEF: "u",
  \u1EF3: "y",
  \u1EF5: "y",
  \u1EF7: "y",
  \u1EF9: "y",
  \u0111: "d",
  // kazakh
  \u04D8: "AE",
  \u04D9: "ae",
  \u0492: "GH",
  \u0493: "gh",
  \u049A: "KH",
  \u049B: "kh",
  \u04A2: "NG",
  \u04A3: "ng",
  \u04AE: "UE",
  \u04AF: "ue",
  \u04B0: "U",
  \u04B1: "u",
  \u04BA: "H",
  \u04BB: "h",
  \u04E8: "OE",
  \u04E9: "oe",
  // serbian
  \u0452: "dj",
  \u0458: "j",
  \u0459: "lj",
  \u045A: "nj",
  \u045B: "c",
  \u045F: "dz",
  \u0402: "Dj",
  \u0408: "j",
  \u0409: "Lj",
  \u040A: "Nj",
  \u040B: "C",
  \u040F: "Dz",
  \u01CC: "nj",
  \u01C9: "lj",
  \u01CB: "NJ",
  \u01C8: "LJ",
  // hindi
  \u0905: "a",
  \u0906: "aa",
  \u090F: "e",
  \u0908: "ii",
  \u090D: "ei",
  \u090E: "ae",
  \u0910: "ai",
  \u0907: "i",
  \u0913: "o",
  \u0911: "oi",
  \u0912: "oii",
  \u090A: "uu",
  \u0914: "ou",
  \u0909: "u",
  \u092C: "B",
  \u092D: "Bha",
  \u091A: "Ca",
  \u091B: "Chha",
  \u0921: "Da",
  \u0922: "Dha",
  \u092B: "Fa",
  \u0917: "Ga",
  \u0918: "Gha",
  \u0917\u093C: "Ghi",
  \u0939: "Ha",
  \u091C: "Ja",
  \u091D: "Jha",
  \u0915: "Ka",
  \u0916: "Kha",
  \u0916\u093C: "Khi",
  \u0932: "L",
  \u0933: "Li",
  \u090C: "Li",
  \u0934: "Lii",
  \u0961: "Lii",
  \u092E: "Ma",
  \u0928: "Na",
  \u0919: "Na",
  \u091E: "Nia",
  \u0923: "Nae",
  \u0929: "Ni",
  \u0950: "oms",
  \u092A: "Pa",
  \u0915\u093C: "Qi",
  \u0930: "Ra",
  \u090B: "Ri",
  \u0960: "Ri",
  \u0931: "Ri",
  \u0938: "Sa",
  \u0936: "Sha",
  \u0937: "Shha",
  \u091F: "Ta",
  \u0924: "Ta",
  \u0920: "Tha",
  \u0926: "Tha",
  \u0925: "Tha",
  \u0927: "Thha",
  \u0921\u093C: "ugDha",
  \u0922\u093C: "ugDhha",
  \u0935: "Va",
  \u092F: "Ya",
  \u092F\u093C: "Yi",
  \u091C\u093C: "Za",
  // azerbaijani
  \u0259: "e",
  \u018F: "E",
  // georgian
  \u10D0: "a",
  \u10D1: "b",
  \u10D2: "g",
  \u10D3: "d",
  \u10D4: "e",
  \u10D5: "v",
  \u10D6: "z",
  \u10D7: "t",
  \u10D8: "i",
  \u10D9: "k",
  \u10DA: "l",
  \u10DB: "m",
  \u10DC: "n",
  \u10DD: "o",
  \u10DE: "p",
  \u10DF: "zh",
  \u10E0: "r",
  \u10E1: "s",
  \u10E2: "t",
  \u10E3: "u",
  \u10E4: "p",
  \u10E5: "k",
  \u10E6: "gh",
  \u10E7: "q",
  \u10E8: "sh",
  \u10E9: "ch",
  \u10EA: "ts",
  \u10EB: "dz",
  \u10EC: "ts",
  \u10ED: "ch",
  \u10EE: "kh",
  \u10EF: "j",
  \u10F0: "h",
  // hebrew
  \u05D1: "v",
  \u05D2\u05BC: "g",
  \u05D2: "g",
  \u05D3: "d",
  \u05D3\u05BC: "d",
  \u05D4: "h",
  \u05D5: "v",
  \u05D6: "z",
  \u05D7: "h",
  \u05D8: "t",
  \u05D9: "y",
  \u05DB: "kh",
  \u05DA: "kh",
  \u05DC: "l",
  \u05DE: "m",
  \u05DD: "m",
  \u05E0: "n",
  \u05DF: "n",
  \u05E1: "s",
  \u05E4: "f",
  \u05E3: "f",
  \u05E5: "ts",
  \u05E6: "ts",
  \u05E7: "k",
  \u05E8: "r",
  \u05EA\u05BC: "t",
  \u05EA: "t"
};
var defaults = {
  locale: "",
  mode: "pretty",
  fallback: true,
  modes: {
    rfc3986: {
      replacement: "-",
      lower: true,
      charmap: initialCharmap,
      multicharmap: initialMulticharmap,
      remove: null,
      trim: true
    },
    pretty: {
      replacement: "-",
      lower: true,
      charmap: initialCharmap,
      multicharmap: initialMulticharmap,
      remove: null,
      trim: true
    }
  }
};

// src/utils/validations.ts
var isAngle = (value, span = 180) => {
  return typeof value === "number" && value >= span * -1 && value <= span;
};
var isEmail = (value) => {
  if (typeof value !== "string" || value.length === 0) {
    return false;
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(value);
};
var isLngLat = (value) => {
  if (!Array.isArray(value) || value.length !== 2) {
    return false;
  }
  const [longitude, latitude] = value;
  if (!isAngle(longitude, 180) || !isAngle(latitude, 90)) {
    return false;
  }
  return true;
};
var isMapBounds = (value) => {
  return Array.isArray(value) && value.length === 2 && value.every(isLngLat);
};
var isPassword = (value) => {
  return typeof value === "string" && value.length >= 8 && /[a-z]/.test(value) && /[A-Z]/.test(value) && /[0-9]/.test(value);
};
var isPeriod = (value) => {
  return value === "monthly" /* Monthly */ || value === "annually" /* Annually */;
};
var isSlug = (value) => {
  return typeof value === "string" && value === slug(value);
};
function isType(types, value) {
  return types.includes(typeof value);
}
var isURL = (value) => {
  if (typeof value !== "string" || !value.length) {
    return false;
  }
  const urlRegex = /^(https?:\/\/)([\da-z\-]+\.)+[a-z]{2,6}(:[0-9]{1,5})?([\/\w \.-]*)*\/?(\?[a-zA-Z0-9=&]*)?$/;
  return urlRegex.test(value);
};
var isNumber = (value) => {
  return typeof value === "number" && !isNaN(value);
};
var isAmountOfMoney = (value) => {
  if (!isNumber(value) || value < 0) {
    return false;
  }
  const decimalPart = value.toString().split(".")[1];
  if (decimalPart && decimalPart.length > 2) {
    return false;
  }
  return true;
};
var isPhoneNumber = (value) => {
  if (typeof value !== "string" || !value.length) {
    return false;
  }
  if (value.length > 15 || value.length < 5) {
    return false;
  }
  const phoneRegex = /^(\+?[0-9])\d*$/;
  return phoneRegex.test(value);
};
var isValidPayFee = (value, amount, period) => {
  if (typeof value !== "boolean" || typeof amount !== "number" || !isPeriod(period)) {
    return false;
  }
  if (value && period === "annually" /* Annually */) {
    return false;
  }
  if (!value && period === "monthly" /* Monthly */ && amount === 1) {
    return false;
  }
  return true;
};
var isNumberInRange = (value, min, max) => {
  if (!isNumber(value)) {
    return false;
  }
  if (typeof min === "number") {
    if (value < min) {
      return false;
    }
  }
  if (typeof max === "number") {
    if (value > max) {
      return false;
    }
  }
  return true;
};
var isTextInRange = (value, minLength = 0, maxLength) => {
  if (typeof value !== "string") {
    return false;
  }
  if (typeof minLength === "number" && value.length < minLength) {
    return false;
  }
  if (typeof maxLength === "number" && value.length > maxLength) {
    return false;
  }
  return true;
};
var isTextInWordRange = (value, minWordLength = 0, maxWordLength) => {
  if (typeof value !== "string") {
    return false;
  }
  const words = value.length > 0 ? value.split(" ") : [];
  if (typeof minWordLength === "number" && words.length < minWordLength) {
    return false;
  }
  if (typeof maxWordLength === "number" && words.length > maxWordLength) {
    return false;
  }
  return true;
};

// src/utils/conversions.ts
var toPhoneNumber = (value) => {
  if (typeof value !== "string" || !value.length) {
    return false;
  }
  const result = value.replace(/(?!^\+)\D/g, "");
  if (!isPhoneNumber(result)) {
    return false;
  }
  return result;
};
var toBytes = (value) => {
  if (typeof value !== "string" || !value.length) {
    return null;
  }
  const lowerCase = value.toLowerCase();
  const units = ["b", "kb", "mb", "gb", "tb", "pb", "eb", "zb", "yb"];
  const [, num, unit] = lowerCase.match(/(\d+)(\w+)/);
  const unitIndex = units.indexOf(unit.toLowerCase());
  if (unitIndex === -1) {
    return null;
  }
  return Number(num) * Math.pow(1024, unitIndex);
};

// node_modules/.deno/date-fns@4.1.0/node_modules/date-fns/constants.js
var daysInYear = 365.2425;
var maxTime = Math.pow(10, 8) * 24 * 60 * 60 * 1e3;
var minTime = -maxTime;
var millisecondsInMinute = 6e4;
var millisecondsInHour = 36e5;
var secondsInHour = 3600;
var secondsInDay = secondsInHour * 24;
var secondsInWeek = secondsInDay * 7;
var secondsInYear = secondsInDay * daysInYear;
var secondsInMonth = secondsInYear / 12;
var secondsInQuarter = secondsInMonth * 3;
var constructFromSymbol = Symbol.for("constructDateFrom");

// node_modules/.deno/date-fns@4.1.0/node_modules/date-fns/constructFrom.js
function constructFrom(date, value) {
  if (typeof date === "function") return date(value);
  if (date && typeof date === "object" && constructFromSymbol in date)
    return date[constructFromSymbol](value);
  if (date instanceof Date) return new date.constructor(value);
  return new Date(value);
}

// node_modules/.deno/date-fns@4.1.0/node_modules/date-fns/toDate.js
function toDate(argument, context) {
  return constructFrom(context || argument, argument);
}

// node_modules/.deno/date-fns@4.1.0/node_modules/date-fns/addDays.js
function addDays(date, amount, options) {
  const _date = toDate(date, options?.in);
  if (isNaN(amount)) return constructFrom(options?.in || date, NaN);
  if (!amount) return _date;
  _date.setDate(_date.getDate() + amount);
  return _date;
}

// node_modules/.deno/date-fns@4.1.0/node_modules/date-fns/addMonths.js
function addMonths(date, amount, options) {
  const _date = toDate(date, options?.in);
  if (isNaN(amount)) return constructFrom(options?.in || date, NaN);
  if (!amount) {
    return _date;
  }
  const dayOfMonth = _date.getDate();
  const endOfDesiredMonth = constructFrom(options?.in || date, _date.getTime());
  endOfDesiredMonth.setMonth(_date.getMonth() + amount + 1, 0);
  const daysInMonth = endOfDesiredMonth.getDate();
  if (dayOfMonth >= daysInMonth) {
    return endOfDesiredMonth;
  } else {
    _date.setFullYear(
      endOfDesiredMonth.getFullYear(),
      endOfDesiredMonth.getMonth(),
      dayOfMonth
    );
    return _date;
  }
}

// node_modules/.deno/date-fns@4.1.0/node_modules/date-fns/add.js
function add(date, duration, options) {
  const {
    years = 0,
    months = 0,
    weeks = 0,
    days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0
  } = duration;
  const _date = toDate(date, options?.in);
  const dateWithMonths = months || years ? addMonths(_date, months + years * 12) : _date;
  const dateWithDays = days || weeks ? addDays(dateWithMonths, days + weeks * 7) : dateWithMonths;
  const minutesToAdd = minutes + hours * 60;
  const secondsToAdd = seconds + minutesToAdd * 60;
  const msToAdd = secondsToAdd * 1e3;
  return constructFrom(options?.in || date, +dateWithDays + msToAdd);
}

// node_modules/.deno/date-fns@4.1.0/node_modules/date-fns/startOfDay.js
function startOfDay(date, options) {
  const _date = toDate(date, options?.in);
  _date.setHours(0, 0, 0, 0);
  return _date;
}

// node_modules/.deno/date-fns@4.1.0/node_modules/date-fns/isDate.js
function isDate(value) {
  return value instanceof Date || typeof value === "object" && Object.prototype.toString.call(value) === "[object Date]";
}

// node_modules/.deno/date-fns@4.1.0/node_modules/date-fns/isValid.js
function isValid(date) {
  return !(!isDate(date) && typeof date !== "number" || isNaN(+toDate(date)));
}

// node_modules/.deno/date-fns@4.1.0/node_modules/date-fns/startOfMonth.js
function startOfMonth(date, options) {
  const _date = toDate(date, options?.in);
  _date.setDate(1);
  _date.setHours(0, 0, 0, 0);
  return _date;
}

// node_modules/.deno/date-fns@4.1.0/node_modules/date-fns/startOfYear.js
function startOfYear(date, options) {
  const date_ = toDate(date, options?.in);
  date_.setFullYear(date_.getFullYear(), 0, 1);
  date_.setHours(0, 0, 0, 0);
  return date_;
}

// node_modules/.deno/date-fns@4.1.0/node_modules/date-fns/startOfHour.js
function startOfHour(date, options) {
  const _date = toDate(date, options?.in);
  _date.setMinutes(0, 0, 0);
  return _date;
}

// node_modules/.deno/date-fns@4.1.0/node_modules/date-fns/startOfMinute.js
function startOfMinute(date, options) {
  const date_ = toDate(date, options?.in);
  date_.setSeconds(0, 0);
  return date_;
}

// node_modules/.deno/date-fns@4.1.0/node_modules/date-fns/startOfSecond.js
function startOfSecond(date, options) {
  const date_ = toDate(date, options?.in);
  date_.setMilliseconds(0);
  return date_;
}

// node_modules/.deno/date-fns@4.1.0/node_modules/date-fns/parseISO.js
function parseISO(argument, options) {
  const invalidDate = () => constructFrom(options?.in, NaN);
  const additionalDigits = options?.additionalDigits ?? 2;
  const dateStrings = splitDateString(argument);
  let date;
  if (dateStrings.date) {
    const parseYearResult = parseYear(dateStrings.date, additionalDigits);
    date = parseDate(parseYearResult.restDateString, parseYearResult.year);
  }
  if (!date || isNaN(+date)) return invalidDate();
  const timestamp = +date;
  let time = 0;
  let offset;
  if (dateStrings.time) {
    time = parseTime(dateStrings.time);
    if (isNaN(time)) return invalidDate();
  }
  if (dateStrings.timezone) {
    offset = parseTimezone(dateStrings.timezone);
    if (isNaN(offset)) return invalidDate();
  } else {
    const tmpDate = new Date(timestamp + time);
    const result = toDate(0, options?.in);
    result.setFullYear(
      tmpDate.getUTCFullYear(),
      tmpDate.getUTCMonth(),
      tmpDate.getUTCDate()
    );
    result.setHours(
      tmpDate.getUTCHours(),
      tmpDate.getUTCMinutes(),
      tmpDate.getUTCSeconds(),
      tmpDate.getUTCMilliseconds()
    );
    return result;
  }
  return toDate(timestamp + time + offset, options?.in);
}
var patterns = {
  dateTimeDelimiter: /[T ]/,
  timeZoneDelimiter: /[Z ]/i,
  timezone: /([Z+-].*)$/
};
var dateRegex = /^-?(?:(\d{3})|(\d{2})(?:-?(\d{2}))?|W(\d{2})(?:-?(\d{1}))?|)$/;
var timeRegex = /^(\d{2}(?:[.,]\d*)?)(?::?(\d{2}(?:[.,]\d*)?))?(?::?(\d{2}(?:[.,]\d*)?))?$/;
var timezoneRegex = /^([+-])(\d{2})(?::?(\d{2}))?$/;
function splitDateString(dateString) {
  const dateStrings = {};
  const array = dateString.split(patterns.dateTimeDelimiter);
  let timeString;
  if (array.length > 2) {
    return dateStrings;
  }
  if (/:/.test(array[0])) {
    timeString = array[0];
  } else {
    dateStrings.date = array[0];
    timeString = array[1];
    if (patterns.timeZoneDelimiter.test(dateStrings.date)) {
      dateStrings.date = dateString.split(patterns.timeZoneDelimiter)[0];
      timeString = dateString.substr(
        dateStrings.date.length,
        dateString.length
      );
    }
  }
  if (timeString) {
    const token = patterns.timezone.exec(timeString);
    if (token) {
      dateStrings.time = timeString.replace(token[1], "");
      dateStrings.timezone = token[1];
    } else {
      dateStrings.time = timeString;
    }
  }
  return dateStrings;
}
function parseYear(dateString, additionalDigits) {
  const regex = new RegExp(
    "^(?:(\\d{4}|[+-]\\d{" + (4 + additionalDigits) + "})|(\\d{2}|[+-]\\d{" + (2 + additionalDigits) + "})$)"
  );
  const captures = dateString.match(regex);
  if (!captures) return { year: NaN, restDateString: "" };
  const year = captures[1] ? parseInt(captures[1]) : null;
  const century = captures[2] ? parseInt(captures[2]) : null;
  return {
    year: century === null ? year : century * 100,
    restDateString: dateString.slice((captures[1] || captures[2]).length)
  };
}
function parseDate(dateString, year) {
  if (year === null) return /* @__PURE__ */ new Date(NaN);
  const captures = dateString.match(dateRegex);
  if (!captures) return /* @__PURE__ */ new Date(NaN);
  const isWeekDate = !!captures[4];
  const dayOfYear = parseDateUnit(captures[1]);
  const month = parseDateUnit(captures[2]) - 1;
  const day = parseDateUnit(captures[3]);
  const week = parseDateUnit(captures[4]);
  const dayOfWeek = parseDateUnit(captures[5]) - 1;
  if (isWeekDate) {
    if (!validateWeekDate(year, week, dayOfWeek)) {
      return /* @__PURE__ */ new Date(NaN);
    }
    return dayOfISOWeekYear(year, week, dayOfWeek);
  } else {
    const date = /* @__PURE__ */ new Date(0);
    if (!validateDate(year, month, day) || !validateDayOfYearDate(year, dayOfYear)) {
      return /* @__PURE__ */ new Date(NaN);
    }
    date.setUTCFullYear(year, month, Math.max(dayOfYear, day));
    return date;
  }
}
function parseDateUnit(value) {
  return value ? parseInt(value) : 1;
}
function parseTime(timeString) {
  const captures = timeString.match(timeRegex);
  if (!captures) return NaN;
  const hours = parseTimeUnit(captures[1]);
  const minutes = parseTimeUnit(captures[2]);
  const seconds = parseTimeUnit(captures[3]);
  if (!validateTime(hours, minutes, seconds)) {
    return NaN;
  }
  return hours * millisecondsInHour + minutes * millisecondsInMinute + seconds * 1e3;
}
function parseTimeUnit(value) {
  return value && parseFloat(value.replace(",", ".")) || 0;
}
function parseTimezone(timezoneString) {
  if (timezoneString === "Z") return 0;
  const captures = timezoneString.match(timezoneRegex);
  if (!captures) return 0;
  const sign = captures[1] === "+" ? -1 : 1;
  const hours = parseInt(captures[2]);
  const minutes = captures[3] && parseInt(captures[3]) || 0;
  if (!validateTimezone(hours, minutes)) {
    return NaN;
  }
  return sign * (hours * millisecondsInHour + minutes * millisecondsInMinute);
}
function dayOfISOWeekYear(isoWeekYear, week, day) {
  const date = /* @__PURE__ */ new Date(0);
  date.setUTCFullYear(isoWeekYear, 0, 4);
  const fourthOfJanuaryDay = date.getUTCDay() || 7;
  const diff = (week - 1) * 7 + day + 1 - fourthOfJanuaryDay;
  date.setUTCDate(date.getUTCDate() + diff);
  return date;
}
var daysInMonths = [31, null, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
function isLeapYearIndex(year) {
  return year % 400 === 0 || year % 4 === 0 && year % 100 !== 0;
}
function validateDate(year, month, date) {
  return month >= 0 && month <= 11 && date >= 1 && date <= (daysInMonths[month] || (isLeapYearIndex(year) ? 29 : 28));
}
function validateDayOfYearDate(year, dayOfYear) {
  return dayOfYear >= 1 && dayOfYear <= (isLeapYearIndex(year) ? 366 : 365);
}
function validateWeekDate(_year, week, day) {
  return week >= 1 && week <= 53 && day >= 0 && day <= 6;
}
function validateTime(hours, minutes, seconds) {
  if (hours === 24) {
    return minutes === 0 && seconds === 0;
  }
  return seconds >= 0 && seconds < 60 && minutes >= 0 && minutes < 60 && hours >= 0 && hours < 25;
}
function validateTimezone(_hours, minutes) {
  return minutes >= 0 && minutes <= 59;
}

// src/utils/date.ts
var dateUnits = ["s", "m", "h", "d", "M", "y"];
var dateUnitMap = {
  y: "years",
  M: "months",
  d: "days",
  h: "hours",
  m: "minutes",
  s: "seconds"
};
var startOf = {
  y: startOfYear,
  M: startOfMonth,
  d: startOfDay,
  h: startOfHour,
  m: startOfMinute,
  s: startOfSecond
};
var relativeDate = /^\$now(?<units>\(((y|M|d|h|m|s):(-?\d+),?)+\))?$/;
var relativeUnit = /(y|M|d|h|m|s):(-?\d+)/g;
var absoluteDate = /^(?<y>\d{4,})(-(?<M>\d\d)(-(?<d>\d\d)([T ](?<h>\d\d)(:(?<m>\d\d)(:(?<s>\d\d))?)?)?)?)?/;
function parseDate2(value, now = /* @__PURE__ */ new Date()) {
  let date;
  let units;
  const relativeMatch = relativeDate.exec(value);
  if (relativeMatch) {
    date = now;
    const unitsGroup = relativeMatch.groups?.units;
    if (unitsGroup) {
      const unitMatches = unitsGroup.matchAll(
        relativeUnit
      );
      units = [];
      for (const [_, unit, delta] of unitMatches) {
        date = add(date, { [dateUnitMap[unit]]: Number(delta) });
        units.push(unit);
      }
    } else {
      units = ["d"];
    }
  } else {
    date = parseISO(value);
    units = Object.entries(absoluteDate.exec(value)?.groups || {}).filter(([_, n]) => !!n).map(([unit]) => unit);
  }
  const minUnit = getMinDateUnit(units) || "s";
  return [startOf[minUnit](date), minUnit];
}
function getMinDateUnit(units) {
  return dateUnits.find((unit) => units.includes(unit));
}
function isValidDate(s) {
  if (typeof s === "string") {
    return relativeDate.test(s) || isValid(parseISO(s));
  }
  return isValid(s);
}
function isDateBetween(date, start, end) {
  let valid = isValidDate(date);
  if (start) {
    start = typeof start === "string" ? parseISO(start) : start;
    valid = valid && date >= start;
  }
  if (end) {
    end = typeof end === "string" ? parseISO(end) : end;
    valid = valid && date <= end;
  }
  return valid;
}

// src/utils/payments.ts
var stripeFees = {
  gb: {
    ["s_card" /* StripeCard */]: (amount) => 0.2 + 0.015 * amount,
    ["s_sepa" /* StripeSEPA */]: () => 0.3,
    ["s_bacs" /* StripeBACS */]: (amount) => Math.min(2, Math.max(0.2, 0.01 * amount)),
    ["s_paypal" /* StripePayPal */]: (amount) => 0.1 + 0.02 * amount
  },
  eu: {
    ["s_card" /* StripeCard */]: (amount) => 0.25 + 0.015 * amount,
    ["s_sepa" /* StripeSEPA */]: () => 0.35,
    ["s_bacs" /* StripeBACS */]: () => 0,
    // Not available
    ["s_paypal" /* StripePayPal */]: (amount) => 0.1 + 0.02 * amount
  },
  ca: {
    ["s_card" /* StripeCard */]: (amount) => 0.3 + 0.029 * amount,
    ["s_sepa" /* StripeSEPA */]: () => 0,
    // Not available
    ["s_bacs" /* StripeBACS */]: () => 0,
    // Not available
    ["s_paypal" /* StripePayPal */]: () => 0
    // Not available
  }
};
var gcFee = (amount) => 0.2 + amount * 0.01;
function calcPaymentFee(feeable, country) {
  const feeFn = feeable.paymentMethod === "gc_direct-debit" /* GoCardlessDirectDebit */ ? gcFee : stripeFees[country][feeable.paymentMethod];
  if (!feeFn) {
    const error = new Error(
      `No fee function found for "${feeable.paymentMethod}" in "${country}". Please check that the payment methods have been configured correctly.`
    );
    console.error(error);
    return 0;
  }
  return feeable.period === "annually" /* Annually */ ? 0 : feeFn(feeable.amount);
}

// src/utils/rules.ts
function isRuleGroup(ruleOrGroup) {
  return "condition" in ruleOrGroup;
}
function validateRule(filters, rule) {
  const filter = filters[rule.field];
  if (!filter) {
    throw new InvalidRule(rule, `Invalid field: ${rule.field}`);
  }
  let expectedArgs = 0;
  if (rule.operator in nullableOperators) {
    if (!filter.nullable && filter.type !== "text" && filter.type !== "array") {
      throw new InvalidRule(
        rule,
        `Invalid nullable operator: field is not nullable`
      );
    }
  } else {
    const operator = operatorsByTypeMap[filter.type][rule.operator];
    if (!operator) {
      throw new InvalidRule(
        rule,
        `Invalid operator for type: ${filter.type} type doesn't define ${rule.operator}`
      );
    }
    expectedArgs = operator.args;
  }
  if (expectedArgs !== rule.value.length) {
    throw new InvalidRule(
      rule,
      `Invalid operator argument count: ${rule.operator} needs ${expectedArgs}, ${rule.value.length} given`
    );
  }
  const expectedType = filter.type === "boolean" || filter.type === "number" ? filter.type : "string";
  if (rule.value.some((v) => typeof v !== expectedType)) {
    throw new InvalidRule(
      rule,
      `Invalid operator argument type: ${rule.operator} needs ${expectedType}, ${rule.value.map(
        (v) => typeof v
      )} given`
    );
  }
  if (filter.type === "date" && rule.value.some((v) => !isValidDate(v))) {
    throw new InvalidRule(
      rule,
      `Invalid operator argument: date type needs valid absolute or relative date, ${rule.value} given`
    );
  }
  if ((filter.type === "enum" || filter.type === "array") && rule.value.some((v) => filter.options?.indexOf(v) === -1)) {
    throw new InvalidRule(
      rule,
      `Invalid operator argument: ${filter.type} type expected ${filter.options}, ${rule.value} given`
    );
  }
  return {
    field: rule.field,
    type: filter.type,
    nullable: !!filter.nullable,
    operator: rule.operator,
    value: rule.value
  };
}
function validateRuleGroup(filters, ruleGroup) {
  const validatedRuleGroup = {
    condition: ruleGroup.condition,
    rules: []
  };
  for (const rule of ruleGroup.rules) {
    const valid = isRuleGroup(rule) ? validateRuleGroup(filters, rule) : validateRule(filters, rule);
    validatedRuleGroup.rules.push(valid);
  }
  return validatedRuleGroup;
}

// src/validators/callout-component-content.validator.ts
var calloutComponentContentValidator = (_, answer) => {
  return !answer;
};

// src/validators/callout-component-input-address.validator.ts
var calloutComponentInputAddressValidator = (_schema, _answer) => {
  throw new Error(
    `[calloutComponentInputAddressValidator] Not implemented yet`
  );
};

// src/validators/callout-component-input-checkbox.validator.ts
var calloutComponentInputCheckboxValidator = (_schema, answer) => {
  return typeof answer === "boolean";
};

// src/validators/callout-component-input-currency.validator.ts
var calloutComponentInputCurrencyValidator = (_schema, answer) => {
  return isAmountOfMoney(answer);
};

// src/validators/callout-component-input-date-time.validator.ts
var calloutComponentInputDateTimeValidator = (schema, answer) => {
  if (typeof answer !== "string") {
    return false;
  }
  return isDateBetween(answer, schema.widget.minDate, schema.widget.maxDate);
};

// src/validators/callout-component-input-email.validator.ts
var calloutComponentInputEmailValidator = (_schema, answer) => {
  return isEmail(answer);
};

// src/validators/callout-component-input-file.validator.ts
var calloutComponentInputFileValidator = (_schema, answer) => {
  if (isFileUploadAnswer(answer)) {
    return isURL(answer.url);
  }
  return false;
};

// src/validators/callout-component-input-number.validator.ts
var calloutComponentInputNumberValidator = (schema, answer) => {
  if (schema.validate && !isNumberInRange(answer, schema.validate.min, schema.validate.max)) {
    return false;
  }
  return isNumber(answer);
};

// src/validators/callout-component-input-phone-number.validator.ts
var calloutComponentInputPhoneNumberValidator = (_schema, answer) => {
  return isPhoneNumber(answer);
};

// src/validators/callout-component-input-select.validator.ts
var calloutComponentInputSelectValidator = (schema, answer) => {
  const optionValue = schema.data.values.find((v) => v.value === answer);
  return !!optionValue;
};

// src/validators/callout-component-input-selectable.validator.ts
var calloutComponentInputSelectableValidator = (schema, answer) => {
  const optionValue = schema.values.find((v) => v.value === answer);
  return !!optionValue;
};

// src/validators/callout-component-input-signature.validator.ts
var calloutComponentInputSignatureValidator = (_, _answer) => {
  throw new Error(
    `[calloutComponentInputSignatureValidator] Not implemented yet`
  );
};

// src/validators/callout-component-input-text.validator.ts
var validateRules = (rules, answer) => {
  if (!rules) {
    return true;
  }
  if (!answer && rules.required) {
    return false;
  }
  if (typeof rules.pattern === "string" && rules.pattern.length && !new RegExp(rules.pattern).test(answer || "")) {
    return false;
  }
  if (!isTextInWordRange(answer, rules.minWords, rules.maxWords)) {
    return false;
  }
  if (!isTextInRange(answer, rules.minLength, rules.maxLength)) {
    return false;
  }
  return true;
};
var calloutComponentInputTextValidator = (schema, answer) => {
  if (typeof answer !== "string") {
    return false;
  }
  return validateRules(schema?.validate, answer);
};

// src/validators/callout-component-input-time.validator.ts
var calloutComponentInputTimeValidator = (_schema, _answer) => {
  throw new Error(
    `[calloutComponentInputTimeValidator] Not implemented yet`
  );
};

// src/validators/callout-component-input-url.validator.ts
var calloutComponentInputUrlValidator = (_schema, answer) => {
  return isURL(answer);
};

// src/validators/callout-component.validator.ts
var calloutComponentNestableValidator = (schema, answers) => {
  for (const component of schema.components) {
    const valid = calloutComponentValidator(
      component,
      answers[component.key]
    );
    if (!valid) {
      return false;
    }
  }
  return true;
};
var calloutInputValidatorsMap = {
  // Input
  ["email" /* INPUT_EMAIL */]: calloutComponentInputEmailValidator,
  ["address" /* INPUT_ADDRESS */]: calloutComponentInputAddressValidator,
  ["checkbox" /* INPUT_CHECKBOX */]: calloutComponentInputCheckboxValidator,
  ["currency" /* INPUT_CURRENCY */]: calloutComponentInputCurrencyValidator,
  ["datetime" /* INPUT_DATE_TIME */]: calloutComponentInputDateTimeValidator,
  ["number" /* INPUT_NUMBER */]: calloutComponentInputNumberValidator,
  ["phoneNumber" /* INPUT_PHONE_NUMBER */]: calloutComponentInputPhoneNumberValidator,
  ["signature" /* INPUT_SIGNATURE */]: calloutComponentInputSignatureValidator,
  ["time" /* INPUT_TIME */]: calloutComponentInputTimeValidator,
  ["url" /* INPUT_URL */]: calloutComponentInputUrlValidator,
  ["file" /* INPUT_FILE */]: calloutComponentInputFileValidator,
  ["select" /* INPUT_SELECT */]: calloutComponentInputSelectValidator,
  // Text
  ["textfield" /* INPUT_TEXT_FIELD */]: calloutComponentInputTextValidator,
  ["textarea" /* INPUT_TEXT_AREA */]: calloutComponentInputTextValidator,
  // Selectable
  ["radio" /* INPUT_SELECTABLE_RADIO */]: calloutComponentInputSelectableValidator,
  ["selectboxes" /* INPUT_SELECTABLE_SELECTBOXES */]: calloutComponentInputSelectableValidator
};
function calloutComponentInputValidator(schema, answer) {
  const validator = calloutInputValidatorsMap[schema.type];
  if (!validator) {
    console.error(`No validator found for ${schema.type}`);
    return false;
  }
  if (answer === void 0) {
    return schema.validate?.required ? false : true;
  }
  const values = Array.isArray(answer) ? answer : [answer];
  for (const value of values) {
    const valid = validator(schema, value);
    if (!valid) {
      return false;
    }
  }
  return true;
}
function calloutComponentValidator(schema, answer) {
  if (isCalloutComponentOfBaseType(schema, 4 /* NESTABLE */)) {
    return calloutComponentNestableValidator(
      schema,
      answer
    );
  }
  if (isCalloutComponentOfBaseType(schema, 1 /* INPUT */)) {
    return calloutComponentInputValidator(
      schema,
      answer
    );
  }
  if (isCalloutComponentOfBaseType(schema, 0 /* CONTENT */)) {
    return calloutComponentContentValidator(schema, answer);
  }
  throw new Error("Invalid schema type");
}
export {
  CONTACT_MFA_TYPE,
  CalloutAccess,
  CalloutCaptcha,
  CalloutChannel,
  CalloutComponentBaseType,
  CalloutComponentType,
  ContributionPeriod,
  ContributionType,
  GetContactWith,
  InvalidRule,
  ItemStatus,
  LOGIN_CODES,
  MembershipStatus,
  NewsletterStatus,
  PaymentMethod,
  PaymentStatus,
  RESET_SECURITY_FLOW_ERROR_CODE,
  RESET_SECURITY_FLOW_TYPE,
  RoleTypes,
  apiKeyFilters,
  calcPaymentFee,
  calloutChannelFilters,
  calloutComponentContentValidator,
  calloutComponentInputAddressValidator,
  calloutComponentInputCheckboxValidator,
  calloutComponentInputCurrencyValidator,
  calloutComponentInputDateTimeValidator,
  calloutComponentInputEmailValidator,
  calloutComponentInputFileValidator,
  calloutComponentInputNumberValidator,
  calloutComponentInputPhoneNumberValidator,
  calloutComponentInputSelectValidator,
  calloutComponentInputSelectableTypes,
  calloutComponentInputSelectableValidator,
  calloutComponentInputSignatureValidator,
  calloutComponentInputTextTypes,
  calloutComponentInputTextValidator,
  calloutComponentInputTimeValidator,
  calloutComponentInputTypes,
  calloutComponentInputUrlValidator,
  calloutComponentInputValidator,
  calloutComponentNestableTypes,
  calloutComponentNestableValidator,
  calloutComponentTypes,
  calloutComponentValidator,
  calloutFilters,
  calloutResponseCommentFilters,
  calloutResponseFilters,
  calloutReviewerFilters,
  calloutTagFilters,
  contactCalloutFilters,
  contactFilters,
  contactTagFilters,
  contentIds,
  dateUnitMap,
  dateUnits,
  filterComponents,
  flattenComponents,
  getCalloutComponents,
  getCalloutFilters,
  getMinDateUnit,
  isAddressAnswer,
  isAmountOfMoney,
  isAngle,
  isCalloutComponentOfBaseType,
  isCalloutComponentOfType,
  isDateBetween,
  isEmail,
  isFileUploadAnswer,
  isLngLat,
  isMapBounds,
  isNumber,
  isNumberInRange,
  isPassword,
  isPeriod,
  isPhoneNumber,
  isRuleGroup,
  isSlug,
  isTextInRange,
  isTextInWordRange,
  isType,
  isURL,
  isValidDate,
  isValidPayFee,
  noticeFilters,
  nullableOperators,
  operatorsByType,
  operatorsByTypeMap,
  parseDate2 as parseDate,
  paymentFilters,
  ruleOperators,
  segmentFilters,
  slug,
  stringifyAnswer,
  tagFilters,
  toBytes,
  toPhoneNumber,
  validateRule,
  validateRuleGroup
};
